<!DOCTYPE html>
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" type="text/css" href="../../assets/css/newwrapper.css">
  <link rel="stylesheet" type="text/css" href="../../assets/css/core-columns.css">
  <link rel="stylesheet" type="text/css" href="../../assets/css/font-awesome/css/font-awesome.min.css">
</head>
<div class="header">
  <img src="http://ipsi.com.au/wp-content/uploads/2017/12/ipsi-logo-red-with-tagline-small.png" alt="Logo">
</div>

<body>
<ng-template paymentHost></ng-template>
</body>







