<!DOCTYPE html>
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" type="text/css" href="../../assets/css/core-columns.css">
  <link rel="stylesheet" type="text/css" href="../../assets/css/font-awesome/css/font-awesome.min.css">
</head>
<div class="header">
  <img src="http://ipsi.com.au/wp-content/uploads/2017/12/ipsi-logo-red-with-tagline-small.png" alt="Logo">
</div>

<div class="full-screen-iframe">
  <body>
  <form>
    <div class="display">
      <div class="confirmation-form">
        <div class="form-group">
          <p class="form-input.clear form-label">Page not found, please check the URL</p>
        </div>
      </div>
    </div>
  </form>
  </body>
</div>






