<!DOCTYPE html>
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" type="text/css" href="../../assets/css/newwrapper.css">
  <link rel="stylesheet" type="text/css" href="../../assets/css/core-columns.css">
  <link rel="stylesheet" type="text/css" href="../../assets/css/font-awesome/css/font-awesome.min.css">
</head>

<!--Amount field-->
<div class="col-12-copy">
  <div class="col-6" *ngIf="data.onlyTokenise != 'true'">
    <label class="fa fa-money inline-field" aria-hidden="true"></label>
    <div class="inline-field valid">
      <i class="fa fa-money valid" aria-hidden="true"></i>
      <input class="form-input field valid-field"
             placeholder="Amount" value="{{ data.amount | number : '1.2-2' }}" readonly>
    </div>
  </div>
</div>

<div class="col-12 row">

  <!--Payment form-->
  <form class="col-6" id="paymentForm" name="paymentForm" [formGroup]="paymentForm">
    <!-- /// IPSI-2042 rectify focus issues -->
    <!--Card Holder Name field-->
    <div class="form-group">
      <label class="fa fa-user inline-field" aria-hidden="true"></label>
      <div class="inline-field" [ngClass]="paymentForm.get('cardHolderName').errors ? 'invalid' : 'valid'">
        <!-- the little icon on the left -->
        <i class="fa fa-money invalid" aria-hidden="true"></i>

        <input class="form-input field" name="cardHolderName" type="cardHolderName"
        [ngClass]="paymentForm.get('cardHolderName').errors ? 'invalid-field' : 'valid-field'"
          formControlName="cardHolderName" placeholder="Card Holder Name"
          value="{{ paymentForm.get('cardHolderName').value }}" [focusMe]="data.isFocusOnName">
        <div class="field-error" *ngIf="paymentForm.get('cardHolderName').errors" [ngClass]="paymentForm.get('cardHolderName').errors ? 'show' : 'hide'">
          <span *ngIf="paymentForm.get('cardHolderName').errors.required">Card Holder Name is required</span>
          <span *ngIf="paymentForm.get('cardHolderName').errors.pattern">Card Holder Name must only include letters or ` - ' characters</span>
        </div>
      </div>
    </div>

    <!--Card Number field-->
    <div class="form-group">
      <label class="fa fa-credit-card inline-field" aria-hidden="true"></label>
      <div class="inline-field invalid"
           *ngIf="(data.pan.dirty || data.pan.touched) && (!data.isPanValid || paymentForm.get('panValue').hasError('required')); else cardNumberValidBlock">
        <i class="fa fa-credit-card invalid" aria-hidden="true"></i>
        <input class="form-input field invalid-field" name="panValue" type="panValue"
               formControlName="panValue" placeholder="Card Number" value="{{ paymentForm.get('panValue').value }}"
               (mousedown)="data.updateData($event, 'pan')" [focusMe]="data.isFocusOnPan" readonly>
        <div class="field-error" *ngIf="paymentForm.get('panValue').hasError('required'); else isPanValidBlock">
          <span>Card Number is required</span>
        </div>
        <ng-template #isPanValidBlock>
          <div class="field-error" *ngIf="!data.isPanValid">
            <span>Card Number is invalid</span>
          </div>
        </ng-template>
      </div>
      <ng-template #cardNumberValidBlock>
        <div class="inline-field valid" *ngIf="paymentForm.get('panValue').value; else cardNumberBlock">
          <i class="fa fa-credit-card valid" aria-hidden="true"></i>
          <input class="form-input field valid-field" #pan name="panValue" type="panValue"
                 formControlName="panValue" placeholder="Card Number" value="{{ paymentForm.get('panValue').value }}"
                 (mousedown)="data.updateData($event,'pan')" [focusMe]="data.isFocusOnPan" readonly>
        </div>
      </ng-template>
      <ng-template #cardNumberBlock>
        <div>
          <input class="form-input resp" name="panValue" type="panValue" formControlName="panValue"
                 placeholder="Card Number" value="{{ paymentForm.get('panValue').value }}" [focusMe]="data.isFocusOnPan"
                 (mousedown)="data.updateData($event,'pan')" readonly>
        </div>
      </ng-template>
    </div>

    <!--Expiry Date field-->
    <div class="form-group">
      <label class="fa fa-calendar-o inline-field" aria-hidden="true"></label>
      <div class="inline-field invalid"
           *ngIf="(data.expiry.dirty || data.expiry.touched) && (!data.isExpiryValid || paymentForm.get('expiryDateValue').hasError('required')); else expiryDateValidBlock">
        <i class="fa fa-calendar-o invalid" aria-hidden="true"></i>
        <input class="form-input field invalid-field" name="expiryDate" placeholder="mm/yy" [textMask]="{mask: data.expiryDateMask}"
               formControlName="expiryDateValue" value="{{ paymentForm.get('expiryDateValue').value }}"
               (mousedown)="data.updateData($event,'expiryDate')" [focusMe]="data.isFocusOnExpiry" readonly>
        <div class="field-error"
             *ngIf="paymentForm.get('expiryDateValue').hasError('required'); else isExpiryValidBlock">
          <span>Expiry date is required</span>
        </div>
        <ng-template #isExpiryValidBlock>
          <div class="field-error" *ngIf="!data.isExpiryValid">
            <span>Expiry date is invalid</span>
          </div>
        </ng-template>
      </div>
      <ng-template #expiryDateValidBlock>
        <div class="inline-field valid" *ngIf="paymentForm.get('expiryDateValue').value; else expiryBlock">
          <i class="fa fa-calendar-o valid" aria-hidden="true"></i>
          <input class="form-input field valid-field" name="expiryDate" type="expiryDate"
                 formControlName="expiryDateValue" placeholder="mm/yy" [textMask]="{mask: data.expiryDateMask}"
                 value="{{ paymentForm.get('expiryDateValue').value }}"
                 (mousedown)="data.updateData($event,'expiryDate')" [focusMe]="data.isFocusOnExpiry" readonly>
        </div>
      </ng-template>
      <ng-template #expiryBlock>
        <div>
          <input class="form-input resp" name="expiryDate" type="expiryDate" formControlName="expiryDateValue"
                 placeholder="mm/yy" value="{{ paymentForm.get('expiryDateValue').value }}" [textMask]="{mask: data.expiryDateMask}"
                 (mousedown)="data.updateData($event,'expiryDate')" [focusMe]="data.isFocusOnExpiry" readonly>
        </div>
      </ng-template>
    </div>

    <!--CVV field-->
    <div class="form-group">
      <label class="fa fa-lock inline-field" aria-hidden="true"></label>
      <div class="inline-field invalid"
           *ngIf="(data.cvv.dirty || data.cvv.touched) && (!data.isCvvValid || paymentForm.get('secureCodeValue').hasError('required')); else cvvValidBlock">
        <i class="fa fa-lock invalid" aria-hidden="true"></i>
        <input class="form-input field invalid-field" name="cvv" placeholder="CVV" formControlName="secureCodeValue"
               value="{{ paymentForm.get('secureCodeValue').value }}" (mousedown)="data.updateData($event, 'cvv')"
               [focusMe]="data.isFocusOnCvv" readonly>
        <div class="field-error" *ngIf="paymentForm.get('secureCodeValue').hasError('required'); else isCvvValidBlock">
          <span>CVV is required</span>
        </div>
        <ng-template #isCvvValidBlock>
          <div class="field-error" *ngIf="!data.isCvvValid">
            <span>CVV is invalid</span>
          </div>
        </ng-template>
      </div>
      <ng-template #cvvValidBlock>
        <div class="inline-field valid" *ngIf="paymentForm.get('secureCodeValue').value; else cvvBlock">
          <i class="fa fa-lock valid" aria-hidden="true"></i>
          <input class="form-input field valid-field" name="cvv" type="cvv"
                 formControlName="secureCodeValue" placeholder="CVV"
                 value="{{ paymentForm.get('secureCodeValue').value }}"
                 (mousedown)="data.updateData($event,'cvv')" [focusMe]="data.isFocusOnCvv" readonly>
        </div>
      </ng-template>
      <ng-template #cvvBlock>
        <div>
          <input class="form-input resp" name="cvv" type="cvv" formControlName="secureCodeValue"
                 placeholder="CVV" value="{{ paymentForm.get('secureCodeValue').value }}" [focusMe]="data.isFocusOnCvv"
                 (mousedown)="data.updateData($event,'cvv')" readonly>
        </div>
      </ng-template>
    </div>
  </form>
  <div class="col-6">
    <div id="info">
      <div class="#info">
        <ul class="card-logos" *ngIf="data.cardTypeValue != 'all'; else allCardTypesBlock">
          <li *ngIf="data.cardTypeValue == 'amex' && data.isAmexAllowed" class="li-card-logo">
            <img src="../../assets/images/cards/AmericanExpress.png">
          </li>
          <li *ngIf="data.cardTypeValue == 'mc'" class="li-card-logo">
            <img src="../../assets/images/cards/mastercardnew.png">
          </li>
          <li *ngIf="data.cardTypeValue == 'visa'" class="li-card-logo">
            <img src="../../assets/images/cards/visa.png">
          </li>
          <li *ngIf="data.cardTypeValue == 'diners' && data.isDinersAllowed" class="li-card-logo">
            <img src="../../assets/images/cards/diners-club-international.png">
          </li>
        </ul>
        <ng-template #allCardTypesBlock>
          <ul class="card-logos"
              style="display: block; border-spacing: 7px; float: none; margin-top: 5px; width: 225px;">
            <li class="li-card-logo" *ngIf="data.isAmexAllowed">
              <img src="../../assets/images/cards/AmericanExpress.png">
            </li>
            <li class="li-card-logo">
              <img src="../../assets/images/cards/mastercardnew.png">
            </li>
            <li class="li-card-logo">
              <img src="../../assets/images/cards/visa.png">
            </li>
            <li class="li-card-logo" *ngIf="data.isDinersAllowed">
              <img src="../../assets/images/cards/diners-club-international.png">
            </li>
          </ul>
        </ng-template>
      </div>
      <h5 class="first-info">{{ data.txnTypeText }}</h5>
      <h5 text-align="center" *ngIf="data.onlyTokenise != 'true'">Amount: ${{ data.amount | number : '1.2-2' }}</h5>
    </div>
  </div>
</div>

<div class="col-12 row">
  <input form="paymentForm" type="submit" id="footerPayButton" value="Submit" [disabled]="!paymentForm.valid || data.disableSubmitButton"
         (click)="data.submitTransaction()">
  <input form="paymentForm" type="reset" class="btn-reset" value="Reset" (click)="data.onReset()">
</div>
